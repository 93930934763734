<template>
  <div class="px-7 py-4" style="background: #f7f7f7; height: auto">
    <v-card flat class="card-datatable">
      <v-card-text style="background: #f7f7f7; ">
        <v-row no-gutters>
          <v-col cols="11" md="4" v-if="this.layout === 'AdminDashboardLayout'">
            <v-text-field
              outlined
              dense
              v-model="teamDataTable[$API_KEYS.SEARCH]"
              label="Search by Name, Email or Role"
              append-icon="mdi-magnify"
              type="text"
              color="#38227A"
              class="search-input"
              height="43px"
            >
              <template v-slot:label>
                <div style="font-size: 15px">Search by Name, Email or Role</div>
              </template>
            </v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn
            color="#38227A"
            dark
            class="px-11 mr-5 text-capitalize"
            @click="toggleAddTeamModal({ show: true, type: 'add' })"
            height="45px"
            style="border: 1px solid #38227a; border-radius: 10px; width: 130px"
            v-if="
              layout === 'AdminDashboardLayout' && $vuetify.breakpoint.smAndUp
            "
          >
            <span>Create new Team</span>
          </v-btn>
          <v-btn
            color="#38227A"
            dark
            class="text-capitalize px-7 mr-5 mb-5"
            height="45px"
            style="border-radius: 10px;"
            @click="toggleAddEditTeamModal({ show: true, type: 'add' })"
            v-if="
              this.adminAccess === 'Super Admin' && $vuetify.breakpoint.smAndUp
            "
          >
            <span>Add New User</span>
          </v-btn>
          <v-menu offset-y v-if="$vuetify.breakpoint.xsOnly">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="mobile-menu"
                >mdi-menu</v-icon
              >
            </template>
            <v-list v-if="layout === 'AdminDashboardLayout'">
              <v-list-item-group active-class="active-class">
                <v-list-item
                  @click="toggleAddTeamModal({ show: true, type: 'add' })"
                >
                  <v-list-item-title>Create New Team</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-list v-if="this.adminAccess === 'Super Admin'">
              <v-list-item-group active-class="active-class">
                <v-list-item
                  @click="toggleAddEditTeamModal({ show: true, type: 'add' })"
                >
                  <v-list-item-title>Add New User</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="table-div">
      <h3 v-if="this.layout != 'CampaignDashboardLayout'">Teams</h3>
      <v-data-table
        fixed-header
        hide-default-footer
        :headers="teamNameDataTable.headers"
        :items="teamNameDataTable.items"
        :loading="teamNameDataTable.loading"
        loading-text="Loading... Please wait"
        class="elevation-1"
        mobile-breakpoint="0"
        v-if="this.layout != 'CampaignDashboardLayout'"
      >
        <template v-slot:item="props">
          <tr class="text-flex" style="font-size: 14px">
            <td class="text-center row-item py-4">
              <div>{{ props.item.team_name }}</div>
            </td>

            <td class="text-center row-item py-4">
              <v-row class="justify-center">
                <v-col class="pa-0 text-center" cols="12" xl="8">
                  <v-chip class="actionColumn-btn" color="#F2F2F2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                          size="22"
                          @click="
                            toggleAddTeamModal({
                              show: true,
                              type: 'edit',
                              id: props.item.id,
                            })
                          "
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </v-chip>
                </v-col>
              </v-row>
            </td>
          </tr>
        </template>
        <template v-slot:footer>
          <div>
            <v-divider></v-divider>
            <v-row no-gutters class="pt-6 px-2">
              <v-col
                class="mb-4"
                align-self="center"
                cols="12"
                xs="12"
                sm="6"
                md="4"
              >
                <div
                  class="justify-center justify-md-start d-flex px-5 tableHeader-text"
                ></div>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </div>
        </template>
      </v-data-table>
      <v-data-table
        fixed-header
        hide-default-footer
        :headers="teamDataTable.headers"
        :items="teamDataTable.items"
        :loading="teamDataTable.loading"
        :items-per-page="
          teamDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        :server-items-length="
          teamDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        :options.sync="dataOptions"
        loading-text="Loading... Please wait"
        class="elevation-1;table-div"
        mobile-breakpoint="0"
      >
        <template v-slot:item="props">
          <tr class="text-flex" style="font-size: 14px">
            <td
              class="text-center row-item py-4"
              v-if="layout === 'AdminDashboardLayout'"
            >
              <div>{{ props.item.first_name }} {{ props.item.last_name }}</div>
            </td>
            <td class="text-center row-item py-4" v-else>
              <div>{{ props.item.name }}</div>
            </td>
            <td
              class="text-center row-item py-4"
              v-if="layout === 'AdminDashboardLayout'"
            >
              <div style="width:190px;">{{ props.item.email }}</div>
            </td>
            <td class="text-center row-item py-4" v-else>
              <div>{{ props.item.email }}</div>
            </td>

            <td
              class="text-center row-item py-4"
              v-if="layout === 'AdminDashboardLayout'"
            >
              <div>{{ props.item.role }}</div>
            </td>
            <td class="text-center row-item py-4" v-else>
              <div>{{ props.item.role }}</div>
            </td>
            <td
              class="text-center row-item"
              v-if="layout === 'AdminDashboardLayout'"
            >
              <div class="pl-10">
                <v-switch
                  color="#067605"
                  v-model="props.item.is_active"
                  @change="changeStatus(props.item.is_active, props.item.id)"
                >
                  <template v-slot:label>
                    <div
                      v-if="props.item.is_active"
                      style="color: #067605; font-size: 14px"
                    >
                      Active
                    </div>
                    <div v-else style="font-size: 14px; color: #8c8c8c">
                      In-active
                    </div>
                  </template>
                </v-switch>
              </div>
            </td>
            <td class="text-center row-item" v-else>
              <div class="pl-10">
                <v-switch
                  color="#067605"
                  v-model="props.item.is_active"
                  @change="changeStatus(props.item.is_active, props.item.id)"
                >
                  <template v-slot:label>
                    <div
                      v-if="props.item.is_active"
                      style="color: #067605; font-size: 14px"
                    >
                      Active
                    </div>
                    <div v-else style="font-size: 14px; color: #8c8c8c">
                      In-active
                    </div>
                  </template>
                </v-switch>
              </div>
            </td>
            <td
              class="text-center row-item py-4"
              v-if="layout === 'AdminDashboardLayout'"
            >
              <div style="width:190px;">{{ props.item.last_logged_in }}</div>
            </td>
            <td class="text-center row-item py-4" v-else>
              <div>{{ props.item.last_logged_in }}</div>
            </td>
            <td
              class="text-center row-item py-4"
              v-if="layout === 'AdminDashboardLayout'"
            >
              <v-row class="justify-center">
                <v-col class="pa-0 text-center" cols="12" xl="8">
                  <v-chip
                    @click="
                      toggleAddEditTeamModal({
                        show: true,
                        type: 'edit',
                        id: props.item.id,
                      })
                    "
                    class="actionColumn-btn"
                    color="#F2F2F2"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                          size="22"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </v-chip>
                  <v-chip
                    @click="
                      toggleDeleteTeamModal({
                        Delete: true,
                        id: props.item.id,
                      })
                    "
                    class="actionColumn-btn"
                    color="#F2F2F2"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                          size="25"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </v-chip>
                </v-col>
              </v-row>
            </td>
            <td class="text-center row-item py-4" v-else>
              <v-row class="justify-center">
                <v-col class="pa-0 text-center" cols="12" xl="8">
                  <v-chip
                    @click="
                      toggleAddEditTeamModal({
                        show: true,
                        type: 'edit',
                        id: props.item.id,
                      })
                    "
                    class="actionColumn-btn"
                    color="#F2F2F2"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                          size="22"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </v-chip>
                  <v-chip
                    @click="
                      toggleDeleteTeamModal({
                        Delete: true,
                        id: props.item.id,
                      })
                    "
                    class="actionColumn-btn"
                    color="#F2F2F2"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                          size="25"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </v-chip>
                  <v-chip
                    class="actionColumn-btn"
                    color="#F2F2F2"
                    @click="resendInvitation(props.item)"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                          size="25"
                        >
                          mdi-email
                        </v-icon>
                      </template>
                      <span>Resend Invitation</span>
                    </v-tooltip>
                  </v-chip>
                </v-col>
              </v-row>
            </td>
          </tr>
        </template>
        <template
          v-slot:footer
          v-if="teamDataTable.items[0] && $vuetify.breakpoint.smAndUp"
        >
          <div>
            <v-divider></v-divider>
            <v-row no-gutters class="pt-6 px-2">
              <v-col
                class="mb-4"
                align-self="center"
                cols="12"
                xs="12"
                sm="6"
                md="4"
              >
                <div
                  class="justify-center justify-md-start d-flex px-5 tableHeader-text"
                >
                  <div class="alignSelf-center mr-3">Show</div>
                  <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                    <v-select
                      class=""
                      :items="itemsPerPage"
                      hide-details
                      v-model="
                        teamDataTable[$API_KEYS.PAGE_INFO][
                          $API_KEYS.ITEMS_PER_PAGE
                        ]
                      "
                      dense
                    ></v-select>
                  </div>

                  <div class="alignSelf-center">
                    {{
                      teamDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                    }}
                    of
                    {{
                      teamDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                    }}
                  </div>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" xs="12" sm="4" md="4">
                <div>
                  <v-pagination
                    v-model="
                      teamDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                    "
                    :length="
                      teamDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                    "
                    :total-visible="5"
                    color="#D30024"
                    class="pagination"
                  >
                  </v-pagination>
                </div>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-card
      v-if="teamDataTable.items[0] && $vuetify.breakpoint.xsOnly"
      flat
      class="footer-card"
    >
      <v-card-text>
        <v-row no-gutters>
          <v-col
            class="mb-4"
            align-self="center"
            cols="12"
            xs="12"
            sm="6"
            md="4"
          >
            <div
              class="justify-center justify-md-start d-flex px-5 tableHeader-text"
            >
              <div class="alignSelf-center mr-3">Show</div>
              <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                <v-select
                  class=""
                  :items="itemsPerPage"
                  hide-details
                  v-model="
                    teamDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
                  "
                  dense
                ></v-select>
              </div>

              <div class="alignSelf-center">
                {{ teamDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER] }}
                of
                {{ teamDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE] }}
              </div>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="4" md="4">
            <div>
              <v-pagination
                v-model="
                  teamDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                "
                :length="
                  teamDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                "
                :total-visible="5"
                color="#D30024"
                class="pagination"
              >
              </v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <add-edit-team-modal @reload="getTeamData"></add-edit-team-modal>
    <add-team-modal @reload="getTeamData"></add-team-modal>
    <delete-team-modal @reload="getTeamData"></delete-team-modal>
    <br />
  </div>
</template>
<script>
import {
  PAGE_INFO,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
  SEARCH,
  DATA,
  SORTING_KEY,
  TEAM_LIST,
} from "@/constants/APIKeys";
import { ITEMS, LOADING } from "@/constants/ModuleKeys";
import {
  API_ADMIN_TEAM_MANAGEMENT_LIST,
  API_ADMIN_USER_MANAGEMENT_LIST,
  API_NEW_TEAM_LIST_GET,
  API_ADMIN_TEAM_MANAGEMENT_UPDATE,
  API_SCHOOL_RESEND_INVITATION,
} from "@/constants/APIUrls";
import _ from "lodash";
import Axios from "@/api/BaseAxios";
import { mapActions, mapGetters } from "vuex";
import authToken from "@/common/authToken";
export default {
  name: "TeamManagement",
  components: {
    AddEditTeamModal: () => import("./AddEditTeamModal/AddEditTeamModal.vue"),
    AddTeamModal: () => import("./AddTeamModal/AddTeamModal.vue"),
    DeleteTeamModal: () =>
      import("./DeleteTeamConfirmation/DeleteTeamConfirmation.vue"),
  },
  data() {
    return {
      adminAccess: localStorage.getItem("adminAccess"),
      dataOptions: { sortBy: [], sortDesc: [] },
      isAdminrole: false,
      teamNameDataTable: {
        headers: [
          {
            sortable: false,
            text: "Name",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg"],
            value: "name",
          },

          {
            sortable: false,
            text: "Actions",
            value: "icon",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
        ],
        [ITEMS]: [],
        [LOADING]: false,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 15,
          [ITEMS_PER_PAGE]: 10,
        },
        [SEARCH]: "",
      },
      teamDataTable: {
        headers: [
          {
            width: "15%",
            sortable: true,
            text: "Name",
            align: "center",
            value: "name",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            sortable: true,
            text: "Email",
            align: "center",
            value: "email",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            sortable: true,
            text: "Role",
            align: "center",
            value: "user_type",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            width: "17%",
            sortable: false,
            text: "Status",
            align: "center",
            value: "status",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            width: "17%",
            sortable: false,
            text: "Last Logged in",
            align: "center",
            value: "role",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            width: "20%",
            sortable: false,
            text: "Actions",
            value: "icon",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
        ],
        [ITEMS]: [],
        [LOADING]: false,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 15,
          [ITEMS_PER_PAGE]: 10,
        },
        [SEARCH]: "",
        [SORTING_KEY]: "",
      },
      itemsPerPage: [10, 15, 20, 30, 50, 100],
    };
  },
  computed: {
    ...mapGetters({
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    userType() {
      return authToken.decodedToken().user_type;
    },
    layout() {
      console.log("layout******", this.$route.meta.layout);
      return this.$route.meta.layout;
    },
  },
  watch: {
    "dataOptions.sortBy": function () {
      console.log(this.dataOptions);

      if (this.dataOptions.sortBy.length !== 0) {
        this.teamDataTable[SORTING_KEY] = this.dataOptions.sortDesc[0]
          ? `-${this.dataOptions.sortBy[0]}`
          : this.dataOptions.sortBy[0];
        if (this.layout === "AdminDashboardLayout") {
          this.getUserData();
        } else {
          this.getTeamData();
        }
      } else {
        if (this.layout === "AdminDashboardLayout") {
          this.teamDataTable[SORTING_KEY] = "";
          this.getUserData();
        } else {
          this.teamDataTable[SORTING_KEY] = "";
          this.getTeamData();
        }
      }
    },
    //Watcher for detecting change in SEARCH
    "teamDataTable.search": function () {
      this.debounceGetTeamManagementData();
    },
    //Watcher for detecting change in PAGE_NUMBER
    "teamDataTable.page_info.page_number": function () {
      this.getTeamData();
    },
    //Watcher for detecting change in ITEMS_PER_PAGE
    "teamDataTable.page_info.detail_count": function () {
      this.getTeamData();
    },
  },
  methods: {
    ...mapActions({
      // team Modal
      toggleAddEditTeamModal: "teamManagement/toggleModal",
      toggleAddTeamModal: "teamManagement/toggleTeamModal",
      toggleDeleteTeamModal: "teamManagement/toggleDeleteModal",
      showToast: "snackBar/showToast",
    }),
    getTeamData() {
      const self = this;
      self.teamDataTable.items = [];
      self.teamDataTable.loading = true;
      console.log("team");
      const successHandler = (res) => {
        console.log(
          "ITEMS_PER_PAGE: ",
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE],
          "PAGE_NUMBER: ",
          res[DATA][PAGE_INFO][PAGE_NUMBER],
          "TOTAL_PAGE: ",
          res[DATA][PAGE_INFO][TOTAL_PAGE],
          "TEAM_LIST: ",
          res[DATA][TEAM_LIST],
          res.data
        );
        self.teamDataTable.items = res[DATA][TEAM_LIST];
        if (self.teamDataTable.items[0].role == "Super Admin") {
          this.isAdminrole = true;
        }
        console.log("this.isAdminrole", this.isAdminrole);

        self.teamDataTable[PAGE_INFO][PAGE_NUMBER] =
          res[DATA][PAGE_INFO][PAGE_NUMBER];
        self.teamDataTable[PAGE_INFO][TOTAL_PAGE] =
          res[DATA][PAGE_INFO][TOTAL_PAGE];
        self.teamDataTable[PAGE_INFO][ITEMS_PER_PAGE] =
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE];

        self.teamDataTable.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.teamDataTable.loading = false;
      };
      let dataTableParams = {};
      dataTableParams[SORTING_KEY] = this.teamDataTable[SORTING_KEY];
      if (this.teamDataTable[PAGE_INFO][ITEMS_PER_PAGE] == 0) {
        dataTableParams[ITEMS_PER_PAGE] = 10;
      } else {
        dataTableParams[ITEMS_PER_PAGE] =
          this.teamDataTable[PAGE_INFO][ITEMS_PER_PAGE];
      }
      if (this.teamDataTable[PAGE_INFO][PAGE_NUMBER] == 0) {
        dataTableParams[PAGE_NUMBER] = 1;
      } else {
        dataTableParams[PAGE_NUMBER] =
          this.teamDataTable[PAGE_INFO][PAGE_NUMBER];
      }
      if (this.layout === "CampaignDashboardLayout") {
        if (this.$route.matched[0].path.substring(1) === "district") {
          dataTableParams["district_id"] =
            this.selectedCampaignData.organizationData.id;
        } else {
          dataTableParams["school_id"] =
            this.selectedCampaignData.organizationData.id;
        }
      }
      console.log(dataTableParams);
      Axios.request_GET(
        API_ADMIN_TEAM_MANAGEMENT_LIST,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    changeStatus(status, id) {
      console.log(status, id);

      const successHandler = (res) => {
        console.log(res.data);
      };
      const failureHandler = (res) => {
        console.log(res.data);
      };
      let formData = {};
      formData["user_id"] = id;
      formData["is_active"] = status;

      Axios.request_PATCH(
        API_ADMIN_TEAM_MANAGEMENT_UPDATE,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getTeamNameData() {
      const self = this;
      self.teamNameDataTable.loading = true;
      const successHandler = (res) => {
        self.teamNameDataTable.items = res.data.team_list;
        try {
          // Save token in local storage
          localStorage.setItem("User_id", res.data.team_list);
        } catch (e) {
          console.log(e);
        }
        self.teamNameDataTable.loading = false;
      };
      const failureHandler = (result) => {
        console.log(result);
        self.teamNameDataTable.loading = false;
      };
      let dataTableParams = {};
      console.log(dataTableParams);
      Axios.request_GET(
        API_NEW_TEAM_LIST_GET,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getUserData() {
      const self = this;
      self.teamDataTable.loading = true;
      const successHandler = (res) => {
        const data = res.data;
        self.teamDataTable.items = data.user_list;
        console.log("User Data", data.user_list);
        if (self.teamDataTable.items[0].role == "Super Admin") {
          this.isAdminrole = true;
        }
        console.log("this.isAdminrole", this.isAdminrole);

        self.teamDataTable[PAGE_INFO][PAGE_NUMBER] =
          res[DATA][PAGE_INFO][PAGE_NUMBER];
        self.teamDataTable[PAGE_INFO][TOTAL_PAGE] =
          res[DATA][PAGE_INFO][TOTAL_PAGE];
        self.teamDataTable[PAGE_INFO][ITEMS_PER_PAGE] =
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE];
        self.teamDataTable.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.teamDataTable.loading = false;
      };
      let dataTableParams = {};
      dataTableParams[SEARCH] = this.teamDataTable[SEARCH];
      dataTableParams[SORTING_KEY] = this.teamDataTable[SORTING_KEY];
      if (this.teamDataTable[PAGE_INFO][ITEMS_PER_PAGE] == 0) {
        dataTableParams[ITEMS_PER_PAGE] = 10;
      } else {
        dataTableParams[ITEMS_PER_PAGE] =
          this.teamDataTable[PAGE_INFO][ITEMS_PER_PAGE];
      }
      if (this.teamDataTable[PAGE_INFO][PAGE_NUMBER] == 0) {
        dataTableParams[PAGE_NUMBER] = 1;
      } else {
        dataTableParams[PAGE_NUMBER] =
          this.teamDataTable[PAGE_INFO][PAGE_NUMBER];
      }
      if (this.layout === "CampaignDashboardLayout") {
        if (this.$route.matched[0].path.substring(1) === "district") {
          dataTableParams["district_id"] =
            this.selectedCampaignData.organizationData.id;
        } else {
          dataTableParams["school_id"] =
            this.selectedCampaignData.organizationData.id;
        }
      }

      console.log(dataTableParams);
      Axios.request_GET(
        API_ADMIN_USER_MANAGEMENT_LIST,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    resendInvitation(item) {
      const successHandler = () => {
        this.showToast({
          message: "Invitation sent successfully.",
          color: "s",
        });
      };

      const failureHandler = () => {};
      let formData = {};
      formData["id"] = item.id;
      Axios.request_GET(
        API_SCHOOL_RESEND_INVITATION,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  created() {
    this.$root.$refs.teamManagement = this;
    if (this.layout === "AdminDashboardLayout") {
      this.debounceGetTeamManagementData = _.debounce(this.getUserData, 500);
    }
    if (this.layout != "AdminDashboardLayout") {
      this.debounceGetCommitteeData = _.debounce(this.getTeamData, 500);
    }
  },
  mounted() {
    if (this.layout != "AdminDashboardLayout") {
      this.getTeamData();
     // location.reload();
    } else if (this.layout === "AdminDashboardLayout") {
      this.getUserData();
    }
    this.getTeamNameData();
  },
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.trow {
  height: 50px;
  background-color: #eeeeee;
  width: 100%;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  font-family: Lato;
}
.footer-card.v-card {
  max-height: 70px;
  font-family: Lato;
  margin-top: 0px;
  border-top: 1px solid #e6e6e6;
}
.alignSelf-center {
  align-self: center;
}
.footer-input.v-input {
  width: 60px;
  padding: 0%;
}
.footer_p {
  margin-left: 25px;
  margin-right: 15px;
  margin-top: 8px;
  color: black;
}
.card-datatable.v-card {
  max-height: 80px;
  margin-bottom: -2px;
  display: flex;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
.tableHeader-text {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 161.5%;
  color: #1d1d1d;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
.v-icon.notranslate.mobile-menu {
  position: relative;
  bottom: 12px;
  right: -7px;
}
.text-capitalize.v-btn >>> span {
  letter-spacing: 0px;
  font-family: Lato;
  font-weight: 600;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  overflow: hidden;
  border-radius: 0px;
}
.pagination {
  float: right;
}
@media (min-width: 0px) and (max-width: 600px) {
  .table-div {
    overflow: scroll;
  }
  .pagination {
    float: none;
  }
  .footer-card.v-card {
    max-height: 200px;
    align-items: center;
    text-align: center;
  }
  .footer_p {
    margin-left: 60px;
  }
  .v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
    /**  min-width: 1200px; */
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .tableHeader-text {
    font-size: 12px !important;
  }
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

@media (min-width: 768px) {
  table {
    table-layout: fixed; /* Fixes the layout of the table */
    width: 100%; /* Ensures the table takes full width */
  }

  td {
    width: 20%; /* Adjust percentage to ensure all columns share equal width */
    text-align: center; /* Center-align content for consistency */
    word-wrap: break-word; /* Prevent text overflow */
    white-space: normal; /* Allow wrapping of text within cells */
  }

  th {
    width: 20%; /* Match header widths with td for alignment */
    text-align: center;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
