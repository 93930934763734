<template>
  <div class="px-7 py-4" style="background: #f7f7f7; height: auto">
     <v-card flat class="card-datatable">
      <v-card-text>
    <v-row>
      <v-col cols="6" md="3">
        <v-text-field
          hide-details=""
          outlined
          :disabled="loading"
          height="43px"
          dense
          v-model="assemblyDataTable[$API_KEYS.SEARCH]"
          label="Search by Event Name,City, Event Support Staff, Sales Rep"
          append-icon="mdi-magnify"
          type="text"
          color="#38227A"
          class="search-input"
          style="margin: 5px"
          v-if="
            this.adminAccess === 'Super Admin' ||
            this.adminAccess == 'Sales Representative' ||
            this.adminAccess == 'Event Support Staff'
          "
        >
        </v-text-field>
      </v-col>
      <!-- v-if="$vuetify.breakpoint.smAndUp"-->
      <v-col
        class="d-flex justify-end text-right"
        cols="4"
        md="3"
        v-if="
          this.adminAccess === 'Super Admin' ||
          this.adminAccess == 'Sales Representative' ||
          this.adminAccess == 'Event Support Staff'
        "
      >
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          max-width="285px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :disabled="loading"
              v-model="dateRangeText"
              label="Filter by Date"
              prepend-inner-icon="mdi-calendar-blank"
              clearable
              v-bind="attrs"
              outlined
              v-on="on"
              dense
              height="43px"
              color="#2C1963"
              style="font-size: 15px; margin: 5px"
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            range
            no-title
            @input="filterDate(dates)"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        class="d-flex justify-end text-right"
        cols="4"
        md="3"
        v-if="
          this.adminAccess === 'Super Admin' ||
          this.adminAccess == 'Sales Representative' ||
          this.adminAccess == 'Event Support Staff'
        "
      >
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          max-width="285px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :disabled="loading"
              v-model="dateRangeText1"
              label="Filter by Created"
              prepend-inner-icon="mdi-calendar-blank"
              clearable
              v-bind="attrs"
              outlined
              v-on="on"
              dense
              height="43px"
              color="#2C1963"
              style="font-size: 15px; margin: 5px"
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates1"
            range
            no-title
            @input="filterDate1(dates1)"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <h3 style="font-family: system-ui" v-if="this.assemblyCount">
        Total Event - {{ this.assemblyCount }}
      </h3>
      <v-btn
        class="text-capitalize px-5"
        height="45px"
        style="
          border-radius: 10px;
          position: unset;
          margin-top: 17px;
          margin-left: 25px;
          margin-bottom: 20px;
        "
        dark
        color="#38227A"
        @click="getResourceDataExport"
        >Export</v-btn
      >
    </v-row>
     </v-card-text>
    </v-card>
    <div class="table-div">
      <v-data-table
        fixed-header
        hide-default-footer
        :headers="assemblyDataTable.headers"
        :items="assemblyDataTable.items"
        :loading="assemblyDataTable.loading"
        :options.sync="dataOptions"
        :items-per-page="
          assemblyDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        :server-items-length="
          assemblyDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        loading-text="Loading... Please wait"
        class="elevation-1"
        mobile-breakpoint="0"
      >
        <template v-slot:item="props">
          <tr>
            <td class="text-center row-item py-4">
              <div class="pl-2 text-center">
                {{ props.item.name }}
                <div
                  class="textHeading"
                  v-if="props.item.assemble_city != null"
                >
                  {{ props.item.assemble_city.toUpperCase() }},
                  {{ props.item.assemble_state }}
                </div>
                <div class="textHeading" style="font-weight: 500">
                  {{ props.item.organization_name }}
                </div>
              </div>
            </td>

            <td class="text-center row-item py-4">
              <div style="font-weight: 600">
                {{ props.item.assemble_date_2 }}
              </div>
              <div class="textHeading" style="font-weight: 500">
                {{ props.item.start_time_str }}- {{ props.item.end_time_str }}
              </div>
            </td>
            <!-- <td class="text-center row-item py-4">
              <div style="font-weight: 600">{{ props.item.host_name}}</div>
                <div style="font-weight: 400">{{ props.item.host_email }}</div>
                <div style="font-weight: 400">{{ props.item.host_phone }}</div>
            </td>-->
            <td class="text-center row-item py-4">
              <div style="font-weight: 600">
                {{ props.item.event_support_staff }}
              </div>
            </td>
            <td class="text-center row-item py-4">
              <div style="font-weight: 600">
                {{ props.item.sales_rep_name }}
              </div>
            </td>
            <td class="text-center row-item py-4">
              {{ props.item.created_on }}
            </td>
            <td class="text-center row-item py-4">
              <span
                v-if="props.item.player_list != null"
                style="font-size: 14px"
              >
                <span
                  v-for="(player, index) in props.item.player_list"
                  :key="index"
                >
                  {{ player.name }},
                </span>
              </span>
              <span v-else style="font-size: 14px">
                {{ props.item.team_name }}
              </span>
            </td>
            <td class="text-center row-item">
              <v-chip
                class="actionColumn-btn"
                color="#F2F2F2"
                @click="
                  toggleAssemblyModal({
                    show: true,
                    type: 'edit',
                    id: props.item.id,
                  })
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="icons"
                      color="#6B6B6B"
                      size="22"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </v-chip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <v-card class="footer-card">
      <v-card-text>
        <v-row no-gutters>
          <v-col
            class="mb-4"
            align-self="center"
            cols="12"
            xs="12"
            sm="6"
            md="4"
          >
            <div
              class="justify-center justify-md-start d-flex px-5 tableHeader-text"
            >
              <div class="alignSelf-center mr-3">Show</div>
              <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                <v-select
                  class=""
                  :items="itemsPerPage"
                  hide-details
                  v-model="
                    assemblyDataTable[$API_KEYS.PAGE_INFO][
                      $API_KEYS.ITEMS_PER_PAGE
                    ]
                  "
                  dense
                ></v-select>
              </div>

              <div class="alignSelf-center">
                {{
                  assemblyDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                }}
                of
                {{
                  assemblyDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                }}
              </div>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="4" md="4">
            <div>
              <v-pagination
                v-model="
                  assemblyDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                "
                :length="
                  assemblyDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                "
                :total-visible="5"
                color="#D30024"
                class="pagination"
              >
              </v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!--<v-card class="mt-10">
        <v-card-title class="py-3">
        <v-row justify="center">
          <v-col> </v-col>
        </v-row>
      </v-card-title>
    <v-card-text>
        <v-row>
          <div class="card-text">
            <p class="heading" style="margin-left: 11px; margin-top: 27px">
              Upload File
            </p>
          </div>
          <br />
        </v-row>
        <v-row>
          <v-col cols="3" class="px-2">
            <v-text-field
              outlined
              dense
              v-model="title"
              label="Title"
              color="#7253CF"
              class="formFields"
              :disabled="formLoading"
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="px-2">
            <v-text-field
              outlined
              dense
              v-model="note"
              label="Note"
              color="#7253CF"
              class="formFields"
              :disabled="formLoading"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="pt-8 px-6"> </v-col>
          <v-col cols="6">
           <label style="font-size: medium;">Upload File</label>
            <v-file-input
              v-model="file"
              prepend-inner-icon="mdi-paperclip"
              prepend-icon=""
              outlined
            
              color="#7254CF"
              dense
              clearable
              :disabled="formLoading"
            >
            </v-file-input>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="3" class="text-right">
            <v-btn
              dark
              style="border-radius: 10px"
              class="mr-4 text-capitalize px-16"
              color="#38227A"
              height="45"
              @click="submitBulkUpload"
              :loading="submitLoading"
            >
              <span>Submit</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>-->
    <assembly-schedual-modal></assembly-schedual-modal>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { ITEMS, LOADING } from "@/constants/ModuleKeys";
import _ from "lodash";
import { ROUTER_URL } from "@/constants/urls";
import { API_ALL_ASSEMBLY_LIST } from "@/constants/APIUrls";
import {
  //   DATA,
  PAGE_INFO,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
  SEARCH,
  SORTING_KEY,
  DATA,
} from "@/constants/APIKeys";
import Axios from "@/api/BaseAxios";
import { mapActions } from "vuex";
export default {
  name: "Upload",
  components: {
    AssemblySchedualModal: () =>
      import(
        "../GameManagement/GameDashboardVASH/AssemblySchedualModal/AssemblySchedualModal"
      ),
  },
  data() {
    return {
      dataOptions: { sortBy: [], sortDesc: [] },
      subAdminAccess: localStorage.getItem("subadminAccess"),
      adminAccess: localStorage.getItem("adminAccess"),
      assemblyCount: "",
      previewFlag: false,
      file: {},
      categoryList: [],
      routeName: "",
      user_id: localStorage.getItem("user_id"),
      note: "",
      title: "",
      category: 1,
      category1: "game",
      formLoading: false,
      submitLoading: false,
      items: [],
      btnLoading: false,
      indexClicked: undefined,
      dates: [],
      menu: false,
      start_date: "",
      end_date: "",
      dates1: [],
      menu1: false,
      start_date1: "",
      end_date1: "",
      exportFlag:false,
      assemblyDataTable: {
        loading: false,
        headers: [
          {
            sortable: true,
            text: "Event Info",
            align: "center",
            value: "city",
            class: ["tableHeader-text", "tableHeader-bg", "px-0"],
          },
          {
            sortable: true,
            text: "Date & Time",
            align: "center",
            value: "date",
            class: ["tableHeader-text", "tableHeader-bg", "px-0"],
          },
          {
            sortable: true,
            text: "Event Support",
            value: "event_support",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            sortable: true,
            text: "Sales Rep",
            value: "sales_rep",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            sortable: true,
            text: "Creation Date",
            value: "created",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg", "px-0"],
          },
          {
            sortable: false,
            text: "Assigned To",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg", "px-0"],
          },
          {
            sortable: false,
            text: "Action",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg", "px-0"],
          },
        ],
        [ITEMS]: [],
        [LOADING]: false,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 15,
          [ITEMS_PER_PAGE]: 50,
        },
        [SEARCH]: "",
        [SORTING_KEY]: "",
      },
      itemsPerPage: [10, 15, 20, 30, 50, 100],
    };
  },
  computed: {
    ...mapGetters({
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    dateRangeText: {
      get() {
        if (this.dates) {
          if (this.dates.length > 1) {
            let date = [
              moment(this.dates[0]).format("MM-DD-YYYY"),
              moment(this.dates[1]).format("MM-DD-YYYY"),
            ];
            return date.join(" - ");
          } else {
            return "";
          }
        } else {
          return "";
        }
        // return this.dates.join("  -  ");
      },
      set(newDates) {
        this.dates = newDates || [];
        return this.dates.length > 1 ? this.dates.join("  -  ") : "";
      },
    },
    dateRangeText1: {
      get() {
        if (this.dates1) {
          if (this.dates1.length > 1) {
            let date = [
              moment(this.dates1[0]).format("MM-DD-YYYY"),
              moment(this.dates1[1]).format("MM-DD-YYYY"),
            ];
            return date.join(" - ");
          } else {
            return "";
          }
        } else {
          return "";
        }
        // return this.dates1.join("  -  ");
      },
      set(newDates) {
        this.dates1 = newDates || [];
        return this.dates1.length > 1 ? this.dates1.join("  -  ") : "";
      },
    },
  },
  created() {
    this.debounceGetResourceData = _.debounce(this.getResourceData, 500);
  },
  watch: {
    dates: function () {
      if (this.dates.length !== 1) {
        this.debounceGetResourceData();
      }
    },
    dates1: function () {
      if (this.dates1.length !== 1) {
        this.debounceGetResourceData();
      }
    },
    //Watcher for detecting change in PAGE_NUMBER
    "assemblyDataTable.page_info.page_number": function () {
      this.debounceGetResourceData();
    },
    //Watcher for detecting change in ITEMS_PER_PAGE
    "assemblyDataTable.page_info.detail_count": function () {
      this.getResourceData();
    },
    //Watcher for detecting change in SEARCH
    "assemblyDataTable.search": function () {
      this.debounceGetResourceData();
    },
    //Watcher for detecting Change in filter by date
    "dataOptions.sortBy": function () {
      console.log(this.dataOptions);
      if (this.dataOptions.sortBy.length !== 0) {
        this.assemblyDataTable[SORTING_KEY] = this.dataOptions.sortDesc[0]
          ? `-${this.dataOptions.sortBy[0]}`
          : this.dataOptions.sortBy[0];
        this.getResourceData();
      } else {
        this.assemblyDataTable[SORTING_KEY] = "";
        this.getResourceData();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleAssemblyModal: "gameManagement/toggleAssembly",
      showToast: "snackBar/showToast",
    }),
    getResourceData() {
      const self = this;
      self.assemblyDataTable.loading = true;
      const successHandler = (res) => {
        self.assemblyCount = res[DATA].assemble_count;
        self.assemblyDataTable.items = res[DATA].assemble_list;
        self.assemblyDataTable[PAGE_INFO][PAGE_NUMBER] =
          res[DATA][PAGE_INFO][PAGE_NUMBER];
        self.assemblyDataTable[PAGE_INFO][TOTAL_PAGE] =
          res[DATA][PAGE_INFO][TOTAL_PAGE];
        self.assemblyDataTable[PAGE_INFO][ITEMS_PER_PAGE] =
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE];

        self.assemblyDataTable.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res, "failed");
        self.assemblyDataTable.loading = false;
      };
      let dataTableParams = {};
      if (this.assemblyDataTable[PAGE_INFO][ITEMS_PER_PAGE] == 0) {
        dataTableParams[ITEMS_PER_PAGE] = 10;
      } else {
        dataTableParams[ITEMS_PER_PAGE] =
          this.assemblyDataTable[PAGE_INFO][ITEMS_PER_PAGE];
      }
      if (this.assemblyDataTable[PAGE_INFO][PAGE_NUMBER] == 0) {
        dataTableParams[PAGE_NUMBER] = 1;
      } else {
        dataTableParams[PAGE_NUMBER] =
          this.assemblyDataTable[PAGE_INFO][PAGE_NUMBER];
      }
      dataTableParams[SORTING_KEY] = this.assemblyDataTable[SORTING_KEY];
      dataTableParams[SEARCH] = this.assemblyDataTable[SEARCH];
      if (this.dates) {
        if (this.dates.length == 2) {
          let filter = {
            start_date: moment(this.dates[0]).format("MM-DD-YYYY"),
            end_date: moment(this.dates[1]).format("MM-DD-YYYY"),
          };
          dataTableParams["filter"] = filter;
        } else {
          dataTableParams["filter"] = {};
        }
        if (this.dates1.length == 2) {
          let filter1 = {
            start_date: moment(this.dates1[0]).format("MM-DD-YYYY"),
            end_date: moment(this.dates1[1]).format("MM-DD-YYYY"),
          };
          dataTableParams["creation_filter"] = filter1;
        } else {
          dataTableParams["creation_filter"] = {};
        }
      }
      Axios.request_GET(
        API_ALL_ASSEMBLY_LIST,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
 getResourceDataExport() {
      const self = this;
      self.assemblyDataTable.loading = true;
      const successHandler = (res) => {
     const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
         let fileName = res.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .split('"')[1];
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        self.assemblyDataTable.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res, "failed");
        self.assemblyDataTable.loading = false;
      };
      let dataTableParams = {};
      if (this.assemblyDataTable[PAGE_INFO][ITEMS_PER_PAGE] == 0) {
        dataTableParams[ITEMS_PER_PAGE] = 10;
      } else {
        dataTableParams[ITEMS_PER_PAGE] =
          this.assemblyDataTable[PAGE_INFO][ITEMS_PER_PAGE];
      }
      if (this.assemblyDataTable[PAGE_INFO][PAGE_NUMBER] == 0) {
        dataTableParams[PAGE_NUMBER] = 1;
      } else {
        dataTableParams[PAGE_NUMBER] =
          this.assemblyDataTable[PAGE_INFO][PAGE_NUMBER];
      }
      dataTableParams[SORTING_KEY] = this.assemblyDataTable[SORTING_KEY];
      dataTableParams[SEARCH] = this.assemblyDataTable[SEARCH];
      if (this.dates) {
        if (this.dates.length == 2) {
          let filter = {
            start_date: moment(this.dates[0]).format("MM-DD-YYYY"),
            end_date: moment(this.dates[1]).format("MM-DD-YYYY"),
          };
          dataTableParams["filter"] = filter;
        } else {
          dataTableParams["filter"] = {};
        }
        if (this.dates1.length == 2) {
          let filter1 = {
            start_date: moment(this.dates1[0]).format("MM-DD-YYYY"),
            end_date: moment(this.dates1[1]).format("MM-DD-YYYY"),
          };
          dataTableParams["creation_filter"] = filter1;
        } else {
          dataTableParams["creation_filter"] = {};
        }
         dataTableParams["export_csv"] ="export_csv";
      }
      Axios.request_GET_BLOB(
        API_ALL_ASSEMBLY_LIST,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    deleteResource(ID) {
      const self = this;
      self.formLoading = true;
      const successHandler = () => {
        self.formLoading = false;

        this.showToast({
          message: "Record deleted",
          color: "s",
        });
        this.getResourceData();
      };
      const failureHandler = () => {
        self.formLoading = false;
      };
      let formData = {};
      console.log(formData);
      Axios.request_DELETE(
        "/game/game_resource/" + ID,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    filterDate(dates) {
      if (dates.length > 1) {
        this.$refs.menu.save(dates);
      }
    },
    filterDate1(dates1) {
      if (dates1.length > 1) {
        this.$refs.menu1.save(dates1);
      }
    },
    showPreview() {
      this.previewFlag = true;
    },
    routeWizfit() {
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.resourceManagement.name,
      });
    },
    route() {
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.gameresourceUpload.name,
      });
      //this.$root.$refs.adminLayout.route("school");
    },
    editResource(item) {
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.gameresourceUpload.name,
        query: { id: item.id },
      });
    },
    videoURL(row) {
      window.open(row, "_blank");
    },
  },
  mounted() {
    this.getResourceData();
  },
};
</script>
<style scoped>
.trow {
  height: 50px;
  background-color: #eeeeee;
  width: 100%;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  font-family: Lato;
}
.footer-card.v-card {
  max-height: 90px;
  font-family: Lato;
  margin-top: 0px;
  border-top: 1px solid #e6e6e6;
  margin-bottom: 20px;
}
.alignSelf-center {
  align-self: center;
}
.footer-input.v-input {
  width: 60px;
  padding: 0%;
}
.footer_p {
  margin-left: 25px;
  margin-right: 15px;
  margin-top: 8px;
  color: black;
}
.card-datatable.v-card {
  max-height: 160px;
  margin-bottom: -2px;
  display: flex;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
.tableHeader-text {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 161.5%;
  color: #1d1d1d;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
.v-icon.notranslate.mobile-menu {
  position: relative;
  bottom: 12px;
  right: -7px;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  border-radius: 0px;
}
.pagination {
  float: right;
}
@media (min-width: 0px) and (max-width: 600px) {
  .pagination {
    float: none;
  }
  .footer-card.v-card {
    max-height: 200px;
    align-items: center;
    text-align: center;
  }
  .footer_p {
    margin-left: 60px;
  }
  .table-div {
    overflow: scroll;
  }
  .v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
    /** min-width: 1200px; */
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .tableHeader-text {
    font-size: 12px !important;
  }
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}

* {
  font-family: Lato;
}
.v-card {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
.card-text {
  color: black;
}
.heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
}
.paragraph {
  margin-top: 5px;
  font-size: 17px;
}
.v-card__title {
  text-align: center;
  background-color: #d30024;
  color: white;
  min-height: 50px;
  font-family: Roboto Slab;
}
.v-input {
  background: #ffffff;
  border-radius: 6px;
}
.v-btn >>> span {
  font-size: 17px;
  font-weight: 600;
}
.btn.text-right.px-2.col-sm-3.col-12 {
  padding-top: 50px;
}
.textHeading {
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.ONGOING {
  background-color: rgba(231, 111, 0, 0.12);
  color: #e76f00;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.UPCOMING {
  background-color: rgba(0, 133, 255, 0.12);
  color: #0085ff;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.COMPLETED {
  background-color: rgba(6, 118, 5, 0.12);
  color: #067605;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
@media (max-width: 600px) {
  .table-div {
    margin-top: 68%;
  }
}
@media (min-width: 0px) and (max-width: 600px) {
  .btn.text-right.px-2.col-sm-3.col-12 {
    padding-top: 0px;
  }
  .card-heading {
    position: absolute;
    left: 130px;
  }
  .card.v-card {
    max-height: 300px;
  }
  .btn {
    position: static;
  }
  .btn1 {
    position: static;
  }
  .v-input {
    margin-bottom: 20px;
  }
  .col-4 {
    /* flex: 0 0 33.3333333333%; */
    max-width: 90.333333%;
    flex: unset;
  }
  .col-6 {
    /* flex: 0 0 33.3333333333%; */
    max-width: 90.333333%;
    flex: unset;
  }
}
</style>
